<template>
  <v-container fluid>

    <template>
      <div style="background-color: rgb(118 126 137);">
        <v-breadcrumbs :items="breadcrumbs">
            <template v-slot:divider>
                <v-icon>mdi-forward</v-icon>
            </template>
        </v-breadcrumbs>
      </div>
    </template>
  
    <v-row class="mt-2">        
      <v-col cols="12">
        <v-card dark outlined>
            
            <v-card-title class="secondary py-1 text-subtitle-1">
                <v-icon left dense> mdi-flag </v-icon>
                Event Configuration
                <v-spacer/>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" lazy-validation>
                   
                    <v-text-field
                        v-model="editedItem.EventName"
                        label="Event Name"
                        :rules="eventNameRules"
                        required
                    ></v-text-field>

                    <!-- event start/end date time -->
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                            v-model="sshowPicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="startDate"
                                    label="Event Start Date"
                                    persistent-hint
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="startDate"
                                no-title
                                @input="sshowPicker = false"
                            ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-menu
                            v-model="sshowPickerTime"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            full-width
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="startTime"
                                    label="Event Start Time"
                                    persistent-hint
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-model="startTime"
                                no-title
                                @input="sshowPickerTime = true"
                            ></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <v-menu
                            v-model="eshowPicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            full-width
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="endDate"
                                    label="Event End Date"
                                    persistent-hint
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="endDate"
                                no-title
                                @input="eshowPicker = false"
                            ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-menu
                            v-model="eshowPickerTime"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            full-width
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="endTime"
                                    label="Event End Time"
                                    persistent-hint
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-model="endTime"
                                no-title
                                @input="eshowPickerTime = false"
                            ></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <!-- event start/end date time -->
                    <v-combobox
                        v-model="editedItem.ShowInPOS"
                        :items="POSTypes"
                        label="Show in POS"
                        :rules="[v => !!v || 'Show in POS is required']"
                        required
                    ></v-combobox>

                    <v-text-field
                        v-model="editedItem.GSTSurchargePercentage"
                        label="GST Percentage"
                        type="number"
                        :rules="CSTSPercentageRules"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="editedItem.CardSurchargePercentage"
                        label="Card Surcharge Percentage"
                        type="number"
                        :rules="CSPercentageRules"
                        required
                    ></v-text-field>

                    <v-row class="mb-2">
                      <v-col>
                        <label>Payment Profile</label>
                        <v-row class="ml-1">
                            <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Cash"
                                value="Cash"
                                class="mr-5"
                            
                            ></v-checkbox>
                            <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Card"
                                value="Card"
                                class="mr-5"
                               
                            ></v-checkbox>
                            <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Voucher"
                                value="Voucher"
                               
                            ></v-checkbox>
                        </v-row>
                      </v-col>
                    </v-row>
                        
                    <label>Printing Profile</label>
                    <v-row class="ml-1">
                        <div v-for="item in PrintingList" :key="item.id">
                        <v-checkbox
                            v-model="editedItem.PrintingProfile"
                            :label="item.name"
                            :value="item.name"
                            class="mr-5"
                            :rules="eventPrintRules"
                            required
                        ></v-checkbox>
                        </div>
                    </v-row>

                    <v-combobox
                        v-model="editedItem.StandardDrinkLimitEnforce"
                        :items="EnforceTypes"
                        item-text="name"
                        label="Drink Limit Profile"
                        :return-object="false"
                        required
                    ></v-combobox>

                    <v-text-field
                        v-model="editedItem.StandardDrinkLimit"
                        label="Maximum Drink Limit"
                        type="number"
                        min="0"
                        :rules="StandardDrinkLimitRules"
                        required
                    ></v-text-field>
                
                    <v-text-field
                        v-model="editedItem.Username"
                        label="Terminal Username"
                        :rules="usernameRules"
                        required
                    ></v-text-field>
                
                    <v-text-field
                        v-model="editedItem.Password"
                        label="Terminal Password"
                        :rules="passwordRules"
                        required
                    ></v-text-field>
                       
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="haddleClickCancel()"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            @click="submitForm"
                        >
                            Submit
                        </v-btn>
                    </v-card-actions>

                </v-form>
            </v-card-text>

        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>


<script>
    import moment from 'moment';
    import 'izitoast/dist/css/iziToast.min.css'; // loading css 
    import iziToast from 'izitoast/dist/js/iziToast.min.js';  // access to iziToast now
    import { mapActions } from 'vuex';
    export default {
      data: () => ({
        breadcrumbs: [
            {
                text: 'Events',
                disabled: false,
                href: '/StallholdersStallsStaffTerminals',
            },
            {
                text: 'Events Configuration',
                disabled: true,
                href: '',
            },
        ],

        sshowPicker: false,
        startDate: null,

        sshowPickerTime: false,
        startTime: null,

        eshowPicker: false,
        endDate: null,

        eshowPickerTime: false,
        endTime: null,

        Action: '',
        editedItem: {
            EventName: '',
            Year: '',
            ShowInPOS: 'Yes',
            GSTSurchargePercentage: 10,
            CardSurchargePercentage: 0,
            PaymentProfile: [],
            PrintingProfile: '',
            StandardDrinkLimitEnforce: 'Prompt',
            StandardDrinkLimit: '',
            Username: '',
            Password: '',
            StartDateTime: '',
            EndDateTime: ''
        },
        PrintingList: [
            {
                id: 1,
                name: "Print"
            },
            {
                id: 2,
                name: "Prompt"
            },
            {
                id: 3,
                name: "Bypass"
            }
        ],

        POSTypes: [
            'Yes',
            'No'
        ],

        EnforceTypes: [
            { id: true, name: 'Enforce'},
            { id: false, name: 'Prompt'}
        ],
    
        eventNameRules: [
            v => !!v || 'Event Name is required',
        ],
        usernameRules: [
            v => !!v || 'Username is required',
        ],
        passwordRules: [
            v => !!v || 'Password is required',
            v => /().{4,}/.test(v) || 'Password must contain at least 4 characters',
        ],
        eventPrintRules: [
            v => !!v || 'Printing Profile is required',
        ],
        CSPercentageRules: [
            v => (v >= 0) || 'Card Surcharge (%) must exist and be equal or greater than 0'
        ],
        CSTSPercentageRules: [
            v => (v >= 0) || 'GST Percentage must exist and be equal to greater than 0'
        ],
        StandardDrinkLimitRules: [
            v => (v && v >= 0) || 'Maximum Drink Limit must exist and be equal to or greater than 0'
        ]

      }),
      computed: {},
      created() {
        if (this.$route.params.id) {
            let eventObj = JSON.parse(localStorage.getItem('setEventObj'));
            this.editItem(eventObj)
            this.haddleClickUpdate(eventObj);
            this.Action = 'update';
        } else {
            this.Action = 'create';
        }
      },
      methods: {
        ...mapActions('admin', ['createEvent', 'getEventManagement']),
        ...mapActions('filter', ['initialLogin']),
        haddleClickUpdate: function (obj) {
            let paramObj = {}
            paramObj.Type = 'event',
            paramObj.id = obj.EventId
            try {
                this.getEventManagement(paramObj).then(response => { 
                    console.log("response")
                    console.log(response)
                }).catch(err => {
                    console.log("err")
                    console.log(err)
                })
            } catch (e) {
              console.log(e)
            }
        },

        editItem: function (eventObj) {
            this.editedItem = eventObj;
            console.log('created() eventObj', eventObj)
            this.editedItem.StandardDrinkLimitEnforce = this.editedItem.StandardDrinkLimitEnforce === true ? 'Enforce' : 'Prompt';
            this.editedItem.ShowInPOS = this.editedItem.ShowInPOS === true ? 'Yes' : 'No'
        },

        submitForm: function () {
            console.log('submitForm() this.editedItem', this.editedItem)
            try {
                if (this.$refs.form.validate()) {
                    this.editedItem.Year = this.startDate.split('-')[0];

                    this.editedItem.startDateTime = this.startDate + ' ' + this.startTime;
                    this.editedItem.startDateTime = moment(this.editedItem.startDateTime).format('YYYYMMDDHHmm');
                    
                    this.editedItem.endDateTime = this.endDate + ' ' + this.endTime;
                    this.editedItem.endDateTime = moment(this.editedItem.endDateTime).format('YYYYMMDDHHmm');

                    this.editedItem.StandardDrinkLimitEnforce = this.editedItem.StandardDrinkLimitEnforce === 'Enforce' ? true : false;
                    this.editedItem.ShowInPOS = this.editedItem.ShowInPOS === 'Yes' ? true: false
                    
                    let event_item = [
                        {
                            Type: "Event",
                            Action: this.Action,
                            Properties: this.editedItem
                        }
                    ]
                    
                    let submitEvent = {
                        event_items: event_item
                    }
                    
                    console.log('submitForm() submitEvent', submitEvent)

                    this.createEvent(submitEvent).then(response => { 

                        iziToast.success({
                            title: 'Event',
                            message: 'Successfully inserted record!',
                            position: 'topRight'
                        });
                    
                        console.log("response")
                        console.log(response)
                        this.redirectReload();
                    }).catch(err => {
                        console.log("err")
                        console.log(err)
                        iziToast.warning({
                            title: 'Event',
                            message: 'Fail to inserted record!',
                            position: 'topRight'
                        });
                    })
                }
            } catch (e) {
                console.log(e)
            }
            },
            redirectReload() {
            this.initialLogin().then(response => {
                console.log(response)
            })
            this.$router.push({ name: 'EventManagement' });

            },
            haddleClickCancel: function () {
            this.$router.push({ name: 'EventManagement' });
            }
      }
    }
</script>